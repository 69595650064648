
/* LOgin styles */
.formAlert{
    position: relative;
    top: 30px;
    right: 0;
    width: 100%;
    color: #f8d7da;
    /* background-color: #f8d7da;
    border-color: #f5c6cb; */
    padding: 5px;
    border-radius: 10px;
}
.floatingAlert{
    position: absolute;
    bottom: 10%;
    right: 10%;
    z-index: 200;
    width: 300px;
    color: #721a21;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 20px 10px;
    border-radius: 10px;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #e3e3e3;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #e3e3e3;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #e3e3e3;
  }
.loginContainer{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: #003399;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10% 0;
}
.welText{
    color: #d7e2f8;
}
.co5{
    width:35%
}
.co3{
    width:30%;
}
.imgDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* input:-internal-autofill-selected {
    background-color: #003399!important;
} */
/* input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px rgb(92, 11, 11, 0.1) inset;
} */
input.field__area:-webkit-autofill,
input.field__area:-webkit-autofill:hover,
input.field__area:-webkit-autofill:focus,
input.field__area:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: #fff!important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: #fff!important;
}

.field {
    position: relative;
    display: block;
    border: 3px solid #fff;
    border-radius: 20px;
    margin-top: 10%;
}
.field__area {
    border: none;
    width: 100%;
    height: 52px;
    display: block;
    padding: 0 30px;
    border-radius: 12px;
    background-color: transparent;
    outline: none;
    color: #fff;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.01em;
}
.field__label {
    top: -12.5px;
    left: 20px;
    position: absolute;
    padding: 0 8px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.02em;
    background-color: #003399;
    color:#fff;
}
.field--error {
    border-color: #E20C0C;
}
.label{
    color:var(--primaryColor);
    font-size: 15px;
}
.input:focus {
    border: 3px solid  linear-gradient(to right top, #003399, #003399)!important;
  }
.field--focus {
    background: linear-gradient(to right top, #003399, #003399);
    border-color: transparent;
}
.socialMedia{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
}
.facebook{
    width:50%;
}
.google{
    width:50%;
}
.facebookspng{
    width: 25px;
}
.lineOne {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 95%;
    background-color:#fff;
    color:  var(--primaryColor);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.02em;
    border-radius: 40px;
}
.lineOne:hover {
    background-color:#fff;
}

.stress{
    margin-left: 10px !important;
}

.btnOutlined {
    margin-top: 30px;
    width: 100%;
    background-color:#ffffff;
    color:  var(--primaryColor);
    padding: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 17px;
    letter-spacing: -0.02em;
    border-radius: 40px;
}
.btnOutlined:hover{
    background-color: #fff;
    color: var(--primaryColor);
}
.checkbox{
    width: auto;
}
.remberText:hover{
    cursor: pointer;
}

@media only screen and (max-width: 992px){
    
.loginListContainer{
    margin: 30% 0;
}
.field__area {
    height: 45px;
    padding: 0 20px;
}

.field__label{
    font-size: 12px;
    left: 10px;
}

.label {
    font-size: 12px;
}
.co5{
    width:10%
}
.co3{
    width:80%
}

.btnOutlined {
    padding: 15px;
    font-size: 18px;
}
.remberText{
    font-size: 18px;
}

}

@media only screen and (max-width: 780px){
    .btnOutlined {
        padding: 10px;
        font-size: 15px;
    }
.remberText{
        font-size: 12px;
        line-height:0.9rem;
        display:flex; align-items:center; justify-items:space-between;
    }



}
    

   
    