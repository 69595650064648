.navbarTog {
    border: 0 solid #fff
}.navbarTog:active {
    border: 0 solid #fff
}
.contain{
    position: absolute;
    top: 2%;
    left: 0;
    padding: 10px 10% 5px;
    z-index: 500;
    width: 100%;
}
a{
    text-decoration: none;
}
.navlink{
    padding-right: 30px!important;
    padding-left: 30px!important;
    /* color:#000; */
}
.btnRounded {
    border-radius: 10px!important;
    background-color: #003399!important;
    color: #fff!important;
    padding:6px 20px!important;
    margin: 0 12px;
    border-color: #003399!important;
}
.btnRoundedOutline {
    border-radius: 10px!important;
    background-color: transparent!important;
    padding:6px 20px!important;
    color: #003399!important;
    border: 2px solid #003399!important;
}

@media only screen and (max-width: 1280px){ 
    .navlink{
            padding-right: 15px!important;
            padding-left: 15px!important;
            font-size: 15px;
        }
}
/* @media (min-width: 992px) {} */
@media only screen and (max-width: 768px){

  
.navlink{
    padding-right: 10px!important;
    padding-left: 10px!important;
    font-size: 12px;
}
.contain{
    padding: 10px 10% 15px;
    background-color: #fff!important;
    /* height: 100vh; */
}


.btnRounded {
    width: 100%;
    padding:4px 10px!important;
    font-size: 12px;
    margin: 10px 0 0 0!important;
}
.btnRoundedOutline {
    width: 100%;
    padding:4px 10px!important;
    font-size: 12px;
}


}