* {
  margin: 0;
  padding: 0;
}
.event__wrapper {
  background-color: #1f1f1f;
  /* background-color: whitesmoke; */
  height: 100vh;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
}
.event__container {
  width: 1200px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.event__header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
}
.event__header h1 {
  font-size: 20px;
  color: #ccc;
  text-transform: uppercase;
}
.event__body__content {
  width: 50%;
  background: #3a3939;
  height: 400px;
  display: flex;
  gap: 10px;
}
.event__video__setup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.event__video__wrapper {
  height: 340px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.event__video__wrapper video {
  width: 100%;
  object-fit: cover;
  height: 350px;
  object-position: center;
}
.event__video__setting {
  height: 0px;
}

.icon {
  font-size: 20px !important;
  color: #ccc;
}
.event__video__setting {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 45px;
  padding: 0px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.camera__off {
  display: flex;
  color: #ccc;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
}
.camera__off h1 {
  font-size: 16px;
  font-weight: bold;
}
.event__button {
  justify-content: flex-start;
  width: 50%;
  display: flex;
}
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
  border: 1px solid #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
