:root {
  --primaryColor: #003399;
  --secondaryColor: #ecefff;
  --white: #ffffff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #000 !important;
}
html {
  scroll-behavior: smooth;
}

body,
p {
  font-family: "Gerbera", sans-serif !important;
}

.sectionOne {
  width: 100vw;
  display: flex;
}
.boxOne {
  height: auto;
  padding: 10% 0;
  width: 50vw;
}
.boxTwo {
  height: auto;
  width: 50vw;
  background-color: var(--secondaryColor);
  z-index: 100 !important;
  padding: 10% 0;
  border-bottom-left-radius: 150px;
}
.slide {
  position: relative;
  top: 10%;
  left: 20%;
  z-index: 500;
  width: 80vw;
}
.slide p {
  font-size: 16px;
  max-width: 70ch;
  margin-left: 5px;
}
.headerTitle {
  font-size: 85px !important;
  line-height: 1;
  font-weight: 600;
}
.action {
  margin: 3% 0;
}
.btnRounded {
  border-radius: 10px !important;
  background-color: var(--primaryColor) !important;
  color: #fff !important;
  padding: 6px 20px !important;
  border-color: var(--primaryColor) !important;
}
.btnRoundedOutline {
  border-radius: 10px !important;
  background-color: transparent !important;
  padding: 6px 20px !important;
  color: var(--primaryColor) !important;
  margin: 0 12px;
  border: 2px solid var(--primaryColor) !important;
}
.downloadApp {
  display: flex;
  margin-top: 40px;
  align-items: center;
  gap: 15px;
}
.downloadApp .dowloadText {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  line-height: 1.3;
}
.icon {
  justify-content: center;
  align-items: center;
}
.store {
  display: flex;
  align-items: center;
  gap: 8px;
}
.iosIcon {
  object-fit: contain;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

/* section two */
.sectionTwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 50px;
  margin: 10% 0;
}
.whyBox {
  background-color: var(--secondaryColor);
  padding: 2px 5px;
  color: var(--primaryColor);
}
.sectionTwoTitle {
  font-weight: 800;
  font-size: 50px;
  max-width: 20ch;
  margin: 10px 0;
  text-align: center;
}
.sectionTwoBox {
  display: flex;
  background-color: var(--primaryColor);
  width: 100%;
  height: 50vh;
  border-radius: 40px;
  color: rgba(255, 255, 255, 0.78);
  padding: 10% 5%;
  margin-top: 8%;
}
.secTwoImg {
  position: relative;
  top: -24vh;
  left: 15%;
  /* width:650px */
}
.sectionTwoBoxOne {
  width: 30%;
}
.sectionTwoBoxOne > p,
.sectionTwoBoxThree > p {
  font-size: 20px;
  margin-top: -20px;
}
.sectionTwoBoxTwo {
  width: 40%;
}
.sectionTwoBoxThree {
  width: 30%;
}

/* section three */
.sectionThree {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 10%;
}
.sectionThreeBoxOne {
  width: 50%;
  justify-content: stretch;
  align-items: center;
}
.sectionThreeBoxTwo {
  width: 50%;
}
.features {
  background-color: var(--secondaryColor);
  padding: 5px 8px;
  font-weight: 600;
  color: var(--primaryColor);
}
.sectionText p {
  font-size: 18px;
  max-width: 50ch;
}
/* .sectionText{
    padding-right: 15%;
} */
.featureTitle {
  font-weight: 800;
  margin-top: 10px;
}
.secThreeImg {
  width: 80%;
}

/* section four */
.sectionFour {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 10%;
}
.sectionFourBoxOne {
  width: 50%;
}
.sectionFourBoxOne .store img {
  width: 150px !important;
  object-fit: contain;
  border-radius: 5px;
  overflow: hidden;
}
.sectionFourBoxTwo {
  width: 50%;
  justify-content: center;
  align-items: center;
}
.secFourImg {
  width: 70%;
}
.liParent {
  list-style-type: none;
}
.liParent li {
  padding: 5px 0;
}
.sectionSix {
  width: 100%;
  background-color: #f4f6ff;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sectionSixBoxThree {
  margin-left: 20px;
}
.sectionSix > .sectionTwoTitle {
  max-width: 25ch;
}
.sectionSixBox {
  display: flex;
}
.secSixImg {
  width: 400px;
}
.mar4 {
  padding-top: 20%;
}
.subText {
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 25px;
}
.subP {
  color: #545454;
}

.firstFooter {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-top: 30px;
}
.main {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2% 30%;
}
.subscribeForm {
  color: #fff;
  border-color: #1659df;
  background-color: #003399;
  width: 70%;
  border: 2px solid #ddd;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 50px;
  line-height: 50px;
  padding: 10px 60px 10px 24px;
}
.submit {
  color: #003399;
  background-color: #fff;
  width: 30%;
  border: 2px solid #fff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 50px;
}
.footerSection {
  background-color: var(--primaryColor);
  display: flex;
  flex-direction: column-reverse;
  padding: 40px 0;
}
.midFooter {
  margin-top: -15px;
}
.iconFoot {
  font-size: 22px;
}
.secFooter {
  border-top-width: 2px;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-top-color: rgba(255, 255, 255, 0.1);
  border-style: solid;
}
.storeFooter {
  display: flex;
  align-items: center;
  margin-top: 10px !important;
  justify-content: space-between;
  height: auto;
}
.storeFooter p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  max-width: 50ch;
}
.storeFooter .downloadText {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  align-items: flex-start;
}
.storeFooter .downloadText .midFooter {
  display: flex;
  gap: 5px;
}
.downloadText {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
}
.storeFooter .downloadA {
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.downloadA {
  display: flex;
  width: 40%;
  align-items: flex-start;
  justify-content: flex-end;
}
.downloadA img {
  margin: 0 10px;
}
.copywrite {
  float: left;
}
.footerNavList {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
}
.list {
  margin-right: 40px;
}
.lastFooter {
  padding: 50px 10%;
}
.footerBoxOne {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* waitlist */
.waitListContainer {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10% 0;
}

.co5 {
  width: 35%;
}
.co3 {
  width: 30%;
}

.field {
  position: relative;
  display: block;
  border: 3px solid var(--primaryColor);
  border-radius: 20px;
  margin-top: 10%;
}
.field__area {
  border: none;
  width: 100%;
  height: 52px;
  display: block;
  padding: 0 30px;
  border-radius: 12px;
  background-color: transparent;
  outline: none;
  color: #000;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.01em;
}
.field__label {
  top: -12.5px;
  left: 20px;
  position: absolute;
  padding: 0 8px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;
  background-color: #fff;
  color: var(--primaryColor);
}
.field--error {
  border-color: #e20c0c;
}
.label {
  color: var(--primaryColor);
  font-size: 15px;
}
.input:focus {
  border: 3px solid linear-gradient(to right top, #0092ff, #01effd) !important;
}
.field--focus {
  background: linear-gradient(to right top, #0092ff, #01effd);
  border-color: transparent;
}
.btnOutlined {
  margin-top: 50px;
  width: 100%;
  background-color: var(--primaryColor);
  color: #ffffff;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 17px;
  letter-spacing: -0.02em;
  border-radius: 40px;
}
.btnOutlined:hover {
  background-color: var(--primaryColor);
  color: #ffffff;
}
.iosFootIcon {
  width: 150px;
  height: 45px;
}
.signSecOne {
  width: 100vw;
  background-color: var(--primaryColor);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10% 0;
}
/* .social-media{
    justify-content: space-between;
} */

/* About us css */
.container {
  padding: 15% 10%;
}
.contain {
  display: flex;
}
.containOne {
  width: 50vw;
}
.containTwo {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.abtTitle {
  font-size: 62px;
  line-height: 68px;
}
.aboutImg {
  width: 80%;
}
.abouthero_text {
  width: 100%;
  padding-top: 24px;
  /* font-size: 18px; */
  line-height: 24px;
}

.analyticSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10% 0;
}
.conOne {
  width: 50vw;
  display: flex;
  align-items: center;
}
.conTwo {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.digital {
  font-size: 62px;
  line-height: 1.2;
}

.ourLine {
  width: 85px;
  height: 2px;
  margin-left: 40px;
  background-color: #152c5b !important;
}
.actionSection {
  display: flex;
  background-color: var(--primaryColor);
  width: 100%;
  height: 50vh;
  border-radius: 40px;
  color: var(--secondaryColor);
  padding: 10% 5%;
  margin-top: 8%;
}

/* Solutions */
.head {
  font-size: 67px;
  text-align: center;
  line-height: 65px;
  letter-spacing: -2.3px;
  margin-bottom: 80px;
}
.solContain {
  display: flex;
}
.solOne {
  width: 50vw;
  display: flex;
  align-items: center;
}
.solTwo {
  width: 50vw;
}
.title {
  font-size: 24px;
}

/* Pricing */
.bodyContainer {
  padding: 15% 10%;
  width: 100vw;
}
.priceContainer {
  display: flex;
  flex-wrap: wrap;
  /* gap: 8px; */
  -ms-flex-pack: justify;
  /* max-width: unset; */
}
.priceCard {
  margin: 20px 0;
  padding: 10px;
  width: 33%;
  /* width: calc(min(31%, 318px));
    margin: 3% 2%; */
}
.plans_cards {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 24px 24px;
  border-radius: 24px;
  background: unset;
  background-image: linear-gradient(
    90deg,
    rgba(251, 80, 29, 0.05) 0%,
    rgba(251, 36, 145, 0.05) 100%
  );
}
.plans_cards_free {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 24px 24px;
  border-radius: 10px;
  background-color: #ebe7e7;
}
.plans_cards_regular {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 24px 24px;
  border-radius: 10px;
  background-color: #ecefff;
}
.plans_cards_premium {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 24px 24px;
  border-radius: 10px;
  background-color: #fafbda;
}
.plans_cards_perEvent {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 24px 24px;
  border-radius: 10px;
  background-color: #ebfdeb;
}
.plans_cards_corporate {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 24px 24px;
  border-radius: 10px;
  background-color: #fcebeb;
}
.plans_cards_custom {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 24px 24px;
  border-radius: 10px;
  background-color: #fbebfd;
}
.plans_cards_pricing {
  /* margin-top: 32px; */
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  /* color: #003399; */
}
.price_amount {
  font-size: 18px;
  line-height: -20px;
  letter-spacing: -0.5px;
}
.price_month {
  font-size: 15px;
}
.plans_cards_save {
  margin-top: 5px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: #83808b;
}
.plans_cards_list {
  margin: 40px 0;
  padding-left: 0rem;
}
.plans_cards_list li:before {
  top: 2px;
  left: 0;
  position: absolute;
  width: 16px;
  height: 16px;
  content: "";
  /* background-image: url("../images/icons/filledCheck.svg"); */
  background-position: center;
  background-size: 100% 100%;
  list-style-type: none;
}
.plans_list {
  list-style-type: none;
}
.plans_choose_free {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #4058df;
  border-radius: 12px;
  background: #4058df;
  height: 52px;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}
.plans_choose_free:hover {
  border: 3px solid #000;
  color: #fff;
}
.accord {
  padding: 0 5%;
  margin-top: 10%;
}

/* 320 480px 768 1280px */

@media only screen and (max-width: 1280px) {
  .slide {
    top: 15%;
  }
  .sectionTwo {
    margin: 20% 0;
  }
  .secTwoImg {
    position: relative;
    top: 4vh;
    left: 0;
    width: 350px;
  }

  .sectionTwoBox {
    width: 100%;
    height: 350px;
    padding: 5% 5%;
  }
  .sectionTwoBoxOne {
    width: 35%;
    display: flex;
    align-items: center;
  }
  .sectionTwoBoxTwo {
    width: 30%;
  }
  .sectionTwoBoxThree {
    width: 35%;
    display: flex;
    align-items: center;
  }
  .secSixImg {
    width: 250px;
  }
  .co5 {
    width: 25%;
  }
  .co3 {
    width: 50%;
  }
}

@media only screen and (max-width: 992px) {
  .secTwoImg {
    position: relative;
    top: 0;
    left: 15%;
    width: 250px;
  }
  /* .br{
        display: none;
    }    */
  .sectionOne {
    display: block;
  }
  .boxOne {
    height: auto;
    width: 100vw;
  }
  .boxTwo {
    height: auto;
    width: 100vw;
    border-bottom-left-radius: 0;
  }
  .slide {
    position: static;
    padding: 10% 10%;
    z-index: 500;
    width: 100vw;
  }
  .sectionTwoBox {
    width: 100%;
    height: auto;
    padding: 5% 5%;
    margin-top: 8%;
  }
  .secTwoImg {
    position: relative;
    top: 15vh;
    left: -10px;
    width: 350px;
  }
  .sectionTwoBoxOne {
    width: 35%;
    display: flex;
    align-items: center;
  }
  .sectionTwoBoxTwo {
    width: 30%;
  }
  .sectionTwoBoxThree {
    width: 35%;
    display: flex;
    align-items: center;
  }

  .sectionThree {
    margin: 20% 0;
    padding: 0 5%;
  }
  .secThreeImg {
    width: 100%;
  }
  .sectionFour {
    padding: 0 10%;
  }
  .secFourImg {
    width: 100%;
  }
  .sectionSix {
    padding: 10%;
  }
  .sectionSixBox {
    flex-direction: column;
  }
  .sectionSixBoxTwo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .secSixImg{
       width: 250px;
   } */
  .mar4 {
    padding-top: 5%;
  }
  .waitListContainer {
    margin: 30% 0;
  }
  .field__area {
    height: 45px;
    padding: 0 20px;
  }

  .field__label {
    font-size: 12px;
    left: 10px;
  }

  .label {
    font-size: 12px;
  }
  .co5 {
    width: 10%;
  }
  .co3 {
    width: 80%;
  }
  /* About css */

  .container {
    padding: 5% 0;
  }
  .contain {
    display: block;
  }

  .containOne {
    width: 100vw;
    position: static;
    padding: 25% 10%;
  }
  .containTwo {
    width: 100vw;
  }
  .abtTitle {
    font-size: 30px;
    line-height: 1em;
  }
  .mdTwo {
    padding: 20% 10%;
  }
  .analyticSection {
    display: block;
    padding: 20% 10%;
  }
  .conOne {
    width: 100vw;
    display: block;
  }
  .conTwo {
    width: 80vw;
    display: block;
  }
  .digital {
    font-size: 30px;
    line-height: 1.2;
  }
  .digitalContain {
    padding: 20% 10%;
  }

  .main {
    padding: 2% 10%;
  }

  .subscribeForm {
    height: 40px;
    padding: 10px 0px 10px 24px;
    font-size: 15px;
  }
  .submit {
    height: 40px;
    font-size: 15px;
  }

  /* Solutions */
  .solContain {
    display: block;
    padding: 25% 10%;
    width: 100vw;
  }
  .solOne {
    width: 100vw;
  }
  .solTwo {
    width: 80vw;
  }

  .title {
    font-size: 18px;
  }

  /* .storeFooter{
    display: none;
} */

  .storeFooter {
    display: none;
    flex-direction: column;
    margin-top: 5% !important;
  }
  .downloadText {
    display: flex;
    width: 100%;
    align-items: center;
    /* justify-content: flex-end; */
  }
  .downloadA {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .downloadA img {
    margin: 0 10px;
  }
  .midFooter {
    padding: 3% 10%;
  }

  /* Pricing */

  .bodyContainer {
    padding: 20% 10%;
    width: 100vw;
  }
  .head {
    font-size: 45px;
    line-height: 1.2em;
    margin-bottom: 80px;
  }
  .priceCard {
    margin-bottom: 10px;
    padding: 10px;
    width: 50%;
  }

  .downloadA {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .footerBoxOne {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;
  }

  .footerNavList {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    list-style-type: none;
    padding: 0px !important;
    margin: 20px 0;
  }
  .list {
    margin: 5px 0px !important;
    width: 50%;
  }
}

@media only screen and (max-width: 540px) {
  .sectionOne {
    display: block;
  }
  .boxOne {
    height: auto;
    width: 100vw;
  }
  .boxTwo {
    height: auto;
    padding: 10%;
    width: 100vw;
    border-bottom-left-radius: 0;
  }
  .downloadApp {
    margin-right: 20%;
  }
  .slide {
    position: static;
    padding: 30% 10%;
    z-index: 500;
    width: 100vw;
  }
  .iosIcon {
    width: 65px;
    height: 25px;
    margin-left: 10px;
    margin-top: 4px;
  }
  .headerTitle {
    font-size: 50px !important;
    line-height: 1;
  }

  .sectionTwo {
    margin: 30% 0;
    padding: 0 10%;
  }
  .sectionTwoBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 20% 10% 10% 10%;
    margin-top: 8%;
    border-radius: 30px;
  }
  .sectionTwoTitle {
    font-weight: 800;
    margin: 2% 0;
    font-size: 25px;
    text-align: center;
  }
  .secTwoImg {
    position: relative;
    top: 15vh;
    left: 5vw;
    width: 280px;
  }
  .sectionTwoBoxOne {
    width: 100%;
  }
  .sectionTwoBoxTwo {
    width: 100%;
    order: 3;
    /* height: 30vh; */
  }
  .sectionTwoBoxThree {
    width: 100%;
  }
  .sectionThree {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
  }
  .sectionThreeBoxOne {
    width: 100%;
    margin: 10% 0;
    order: 2;
  }
  .sectionThreeBoxTwo {
    width: 100%;
  }
  .secThreeImg {
    width: 100%;
  }
  .sectionFour {
    display: flex;
    flex-direction: column;
    order: 2;
    padding: 0 10%;
  }
  .sectionFourBoxOne {
    width: 100%;
    margin: 10% 0;
  }
  .sectionFourBoxTwo {
    width: 100%;
  }
  .br {
    display: none;
  }
  .sectionSixBox {
    flex-direction: column;
  }
  .secSixImg {
    width: 250px;
  }
  /* .list{
        margin-right: 10px;
    } */
  /* pricing */

  .bodyContainer {
    padding: 40% 10%;
  }
  .head {
    font-size: 45px;
  }
  .priceCard {
    width: 100%;
  }
}

@media only screen and (max-width: 340px) {
  .slide {
    padding: 40% 10%;
  }
  .paragraph {
    font-size: 12px;
  }
  .headerTitle {
    font-size: 35px !important;
  }
  .downloadApp {
    display: flex;
    flex-direction: column;
  }
  .icon {
    display: none;
  }
  .btnRounded {
    font-size: 12px;
    padding: 6px 10px !important;
  }
  .btnRoundedOutline {
    font-size: 12px;
    padding: 6px 10px !important;
  }
  .secTwoImg {
    position: relative;
    top: 15vh;
    left: 5vw;
    width: 200px;
  }
  .sectionTwoTitle {
    font-size: 20px;
  }
  .featureTitle,
  .subText {
    font-size: 18px;
  }
  .featureText,
  .features {
    font-size: 12px;
  }
  .liParent,
  .subP {
    font-size: 12px;
  }
}
